import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="statistic"
export default class extends Controller {
  static targets = ['input']
  connect() {
  }

  inputTargetConnected(target) {
      target.addEventListener('click',(e) => this.sendStatistics(target));
  }

  sendStatistics(e) {
      const data = new FormData();
      data.append("stats", e.dataset.stats);

      const url = `${this.element.dataset.statsIncrementUrl}?stats=${e.dataset.stats}`;
      fetch(url, { method: "POST", body: data })
          .then((response) => response)
          .then((json) => {
              return json;
          })
          .catch((error) => {
              throw(error);
          });
  }
}
