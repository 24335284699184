import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['inputs']

  inputsTargetConnected(target) {
    target.addEventListener("change", (e) => this.reloadForm(e, target));
  }

  reloadForm(e, target) {
    target.parentNode.appendChild(this.createHiddenFormInput('reload', 'true'));
    target.parentNode.appendChild(this.createHiddenFormInput('reload_submitter', this.element.id));
    target.form.requestSubmit();
  }

  createHiddenFormInput(name, value) {
    const newInput = document.createElement('input');
    newInput.setAttribute('value',value);
    newInput.setAttribute('name',name);
    newInput.setAttribute('style', 'display: none;');
    return newInput;
  }

}