import { Controller } from "@hotwired/stimulus"
import Swal from "sweetalert2";

// Connects to data-controller="confirm"
export default class extends Controller {
  listenerMethod = (e) => this.confirm(e);

  connect() {
    this.element.addEventListener("click", this.listenerMethod);
  }

  getLocale() {
    return document.body.dataset.locale || 'fr';
  }

  confirm(e) {
    e.preventDefault();

    const title = this.element.dataset.title;
    const message = this.element.dataset.text;
    const confirmButton = this.element.dataset.confirmButton;
    const cancelButton = this.element.dataset.cancelButton;

    Swal.fire({
      title: title || 'Are you sure?',
      html: message || '',
      showCancelButton: true,
      confirmButtonText: confirmButton || (this.getLocale() === 'fr' ? 'Oui' : 'Yes'),
      cancelButtonText: cancelButton || (this.getLocale() === 'fr' ? 'Non' : 'No'),
    }).then((result) => this.handleConfirmation(result));
  }

  handleConfirmation(result) {
    if (result.value) {
      this.element.removeEventListener("click", this.listenerMethod);
      this.element.click();
    }
  }
}
