import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="ellipsis"
export default class extends Controller {
  connect() {
    const handleActive = () => {
      if (this.element.textContent.endsWith("...") ) {
        this.element.classList.add("--ellipsized");
      }
    };

    const handleInactive = () => {
      this.element.classList.remove("--ellipsized");
    };

    this.element.addEventListener("mouseover", handleActive);
    this.element.addEventListener("focus", handleActive);

    this.element.addEventListener("mouseout", handleInactive);
    this.element.addEventListener("blur", handleInactive);
  }
}
